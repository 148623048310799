import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import { lerp } from './uitls';
import ButtonScaleAnim from './ButtonScaleAnim';
import ImageUI from './ImageUI';

export default class PG_Image {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);

        const url = window.location.search;
        const params = new URLSearchParams(url);
        const id = params.get("id");
        if (id) {
            this.id = id;
        } else {
            window.location.href = '/'
        }

        this.container = document.querySelector('.layer-detail')


        this.textColorArr = {
            "hot": "#FAB6AD",
            "like": "#FFE79D",
            "dislike": "#9AEEFF",
            "confused": "#F7B1F7",
        }

        this.buttonIndex = {
            "hot": 2,
            "like": 3,
            "dislike": 1,
            "confused": 0
        }


        this.buttonReactions = []

        this.isInit = false;
        this.init()
    }

    init() {
        console.log('init : PAGE_Index ', this.app)
        if (this.id) {
            this.getDATA()
        }
    }

    async getDATA(i) {
        try {
            const response = await this.app.getDetailData(this.id)

            if (response) {
                if (!this.isInit) {
                    this.isInit = true;
                    this.createItem(response)
                } else {
                    this.updateReaction(response.reaction_sheet, i)
                }
                
            }
        } catch(error) {
            console.log(error)
        }
    }

    updateReaction(reaction_sheet, index) {
        reaction_sheet.forEach((r, i) => {
            this.buttonReactions[i].count(r.total_reaction_count, i == index)
        })
    }

    async eventReaction(button, to, imageId) {
        try {

            if (to === 'delete') {
                const response = await this.app.deleteReaction(button.getAttribute('data-id'));
                if (response) {
                    console.log('Reaction : deletee ', response)
                    button.removeAttribute('data-id');
                }

            } else if (to === 'create') {
                const response = await this.app.createReaction(imageId, button.getAttribute('data-reaction-type'));
                if (response) {
                    console.log('Reaction : create ', response.id)
                    button.setAttribute('data-id', response.id)
                }
            }
        } catch(error) {
            console.log('error')
        }

    }
    createItem(data) {

        const { generative_image, reaction_sheet, created_by, id } = data;

        console.log(data)
 
        const created = {
            id: '',
            name: '', 
            team_name: '',
            user_profile_url: ''
        }

        if (created_by) {
            Object.entries(created_by).forEach(([key, value]) => {
                if (key in created) {
                    created[key] = value;
                }
            });
        }


        const content = document.createElement('div');
        content.classList.add('layer-inner')

        content.style.setProperty('--ox', this.opacityValue);

        
        content.innerHTML = `     
           <div class="item-image">
                <div class="thumb">
                    <img src="${generative_image}" alt="">
                </div>

                <div class="reaction-container"></div>
            </div>
        
            <a class="tag" href="/account?id=${created.id}" target="_self">
                <span class="tag-leading">
                    <span class="tag-text tag-name">${created.name}</span>
                </span>
                <span class="tag-trailing">
                    <span class="tag-text tag-team">${created.team_name}</span>
                </span>
            </a>
        `;



        if (reaction_sheet) {
            const reactionContainer = content.querySelector('.reaction-container');


            console.log(reactionContainer)
            reaction_sheet.map((react, i) => {
                const button = document.createElement('button');

                button.classList.add('button-reaction');
                button.innerHTML = `
                    <span class="inner">
                    <span class="background"></span>
                    <span class="background --fill"></span>
                    <span class="symbol"></span>
                    
                    <span class="count text-container">
                        <span class="text-content">${react.total_reaction_count}</span>
                        <span class="text-content">${react.total_reaction_count}</span>
                    </span>
                    </span>
                `;


                // if (react.name === 'hot') {
                //     button.innerHTML += ` <svg>
                //         <defs>
                //           <clipPath id="mask-hot" clipPathUnits="objectBoundingBox">
                //             <path d="m115.47,55.99c-.58-.48-1.43-.44-1.96.1-1.98,2.01-6.73,6.57-8.83,6.57-2.69,0-5.12-1.3-5.12-3.94s1.92-12.72,2.29-22.32c.06-1.54,1.4-29.84-31.44-31.22-1.82-.08-2.94,1.92-1.92,3.42,3.17,4.66,5.6,9.43,4.44,13.56-.69,2.46-14.53,22.48-14.59,22.48h0c-2.9,3.75-4,8.35-4.4,13.29-.03.38-.56.49-.73.14-1.75-3.64-2.61-12.58-2.93-17.3-.09-1.37-1.68-2.06-2.77-1.24-1.63,1.23-3.11,2.62-4.44,4.16-.56.66-1.62.42-1.85-.41-2.29-8.43-10.29-14.54-19.63-13.99-9.51.56-17.26,8.16-17.92,17.59-.77,11.08,8.07,20.32,19.08,20.32,3.53,0,6.83-.95,9.67-2.6.77-.45,1.72.16,1.6,1.04-.41,2.94-.61,5.96-.61,9.01,0,24.4,19.32,44.17,45.62,44.17s44.59-19.82,44.59-44.22c0-7.52-2.32-13.85-8.13-18.61h0Zm-36.7,57.37c-13.94,0-24.19-10.48-24.19-23.42,0-7.91,1.96-13.8,6.56-17.88.92-.81,2.38-.21,2.47,1,.07.87.17,1.86.29,2.87.17,1.37,2.07,1.62,2.6.35.33-.8.75-1.56,1.3-2.26.03,0,6.78-10.34,7.74-14.36.95-4.02,5.26-6.23,8.61-4.86,9.73,3.97,5.5,25.29,5.5,26.69s1.29,2.09,2.72,2.09c.99,0,3.1-1.92,4.29-3.09.51-.5,1.34-.54,1.87-.06,2.76,2.48,3.87,5.7,3.87,9.49,0,12.94-9.7,23.44-23.64,23.44h0Z"/>
                //           </clipPath>
                //         </defs>
                //       </svg>`
                // }

                button.classList.add(`reaction-${react.name}`)
                button.setAttribute('data-text-color', this.textColorArr[react.name]);
                button.setAttribute('data-index', this.buttonIndex[react.name]);
                button.setAttribute('data-reaction-type', react.id);
   
                const _buttonAnim = new ButtonScaleAnim(button, react.total_reaction_count)
                this.buttonReactions.push(_buttonAnim);
                if (react.user_reactions.is_reaction) {
                    button.classList.add('selected');
                    button.setAttribute('data-id', react.user_reactions.id)
                    
                
                    gsap.delayedCall(i * 0.08, () => {
                        this.buttonReactions[i].introSelect()
                    })
                } else {
                    this.buttonReactions[i].introText()
                }
                reactionContainer.appendChild(button);

                button.addEventListener('click', () => {
                    // if (button.classList.contains('is-animating')) return;

                    if (button.classList.contains('selected')) {
                        console.log('click : is selected / 선택 -> 미선택')
                        this.buttonReactions[i].release('unselect')
                        // 비동기로 반응 삭제 처리
                        this.eventReaction(button, 'delete', button.getAttribute('id'));
                        
                        // if (response) { // 삭제가 성공했을 경우만 unselect 실행
                        
                        // this.buttonReactions[i].unselect();
                        // this.buttonReactions[i].countDown()
                        // button.classList.remove('selected')
                        // button.removeAttribute('data-id');
                        // this.getDATA(i)
                        // dispatch({ type: 'REMOVE_REACTION', payload: { id: react.id } }); // 상태 업데이트
                        // }
                    } else {
                        // 비동기로 반응 생성 처리
                        console.log('click : is selected / 미선택 -> 선택')
                        button.getAttribute('data-id')
                        // const response = await this.app.createReaction(id, react.id);
                        this.buttonReactions[i].release('select')
                        this.eventReaction(button, 'create', id)
                        // this.buttonReactions[i].select();
                        // this.buttonReactions[i].countUp()
                        // button.classList.add('selected')
                        // button.setAttribute('data-id', response.id)
                        
                    }
                });                
            })
        }

        // content.addEventListener('click', this._initClick)

        content.querySelector('img').onload = function(){
            //이미지가 로드 된 후 실행할 코드
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };

        this.container.appendChild(content);
        this.initImageEvents(this.container.querySelector('.thumb img'));

        const layer = this.container.querySelector('.reaction-container');
        if (layer) {
            layer.style.setProperty('--font', `${(layer.getBoundingClientRect().width / 100)}px`);
            window.onresize = () => {
                layer.style.setProperty('--font', `${(layer.getBoundingClientRect().width / 100)}px`);
            }
        }
    }


    initImageEvents(image) {

        this.UI = new ImageUI(this.container);
        let pressTimer; // Long press timer
        const longPressDuration = 500; // 500ms threshold for a long press
    
   
        // 클릭 시 스케일 트랜지션
        const handleClick = () => {
            image.classList.add("scaled");
            setTimeout(() => {
                image.classList.remove("scaled");
            }, 300); // 트랜지션 시간이 끝난 후 원래 상태로 복구

            this.UI.transition()
            
        };
        
        // 꾹 누르면 사진 저장
        const handleLongPress = () => {
            const link = document.createElement("a");
            link.href = image.src; // 이미지 URL
            link.download = "saved-image.jpg"; // 저장될 파일 이름
            // link.click();
        };
        
        // 마우스 버튼을 눌렀을 때
        image.addEventListener("mousedown", () => {
            pressTimer = setTimeout(handleLongPress, longPressDuration);
        });
        
        // 마우스 버튼을 떼거나 화면에서 나갔을 때
        image.addEventListener("mouseup", () => {
            clearTimeout(pressTimer); // 타이머 중지
        });
        
        image.addEventListener("mouseleave", () => {
            clearTimeout(pressTimer); // 타이머 중지
        });
        
        // 클릭 이벤트
        image.addEventListener("click", handleClick);

    }
}