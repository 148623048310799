import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import gsap from 'gsap';
import CropImage from './CropImage';
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { domToJpeg } from 'modern-screenshot';

import GenerateUI from './GenerateUI'
import p5 from "p5";
import sketch from './sketch';


export default class PG_Generate {
    constructor(app) {
        // console.log('Init : PG_Generate')
        this.app = app;

        this.resultImage = 'https://replicate.delivery/xezq/DxEQw5pCVIJCA9XJo08DG1sakWDeVeebQB5jJ8QL4Qc6RYynA/out-0.jpg'

        this.frames = [];

        const titles = [
            '1_The-Person-of-the-Year',
            '2_Creative-Partner',
            '3_Gentle',
            '4_Ace-Card',
            '5_Holiday',
            '6_Christmas',
            '7_GOAT',
            '8_Yellow-Jacket',
            '9_King-Mask',
            '10_Wanted',
            '11_Capture-the-moment',

        ]

        for (let i = 0; i < titles.length; i++) {
            this.frames.push({
                name: titles[i].split('_')[1].split('-').join(' '),
                card_frame: `/assets/frame/${titles[i]}.png`
            })
        }

        // this.getFrames()

        this.backgroundColor = 'rgb(0,0,0)';
        this.layers = gsap.utils.toArray('.layer-generate');

        this.buttonGenerate = gsap.utils.toArray('.button-generate')
        this.buttonTakePhoto = document.querySelector('.button-continue')
        this.buttonContinue = document.querySelector('.button-continue-progress')
        this.buttonPost = gsap.utils.toArray('.button-post')
        this.buttonSave = document.querySelector('.button-save');


        this.buttonFlow = document.querySelector('.button-replicate-flow');
        this.cropper = null;

        this.current = 0;

        this.imageQuality = 0.8;

        this.UI = new GenerateUI(this);

        this.CURRENT_REQUEST = null;
        this.temp = []

        this.init()


        this.buttonPost.forEach(button => button.disabled = true)
    }

    init() {
        Swiper.use([Pagination ]);
        this.initInputImage()
        this.initGenerate()

        this.initPost()
        this.initSave()

        this.initContinue()
        // this.generateSelectFrame()
        // this.gameToResult()
        this.UI.progress_Onboard('appear');
        // this.progress_Game('init')
        // gsap.delayedCall(0.2, () => {
            // 
            // this.UI.progress_Loading('appear')
    // 
            // gsap.delayedCall(2, () => {
                // this.UI.progress_Loading('disappear')
            // })
        // })

        // this.initGame()
        // this.progress_Game('init')
        // console.log(this.Game)
    }
    initContinue() {
        
        this.buttonTakePhoto.addEventListener('click', () => {
            console.log('결과 확인하기')
            this.buttonTakePhoto.disabled = true;
            // console.log('click continue')
            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()
            }
            this.UI.progress_Game('disappear', _callback)
        })

        this.buttonContinue.addEventListener('click', () => {
            this.buttonContinue.disabled = true;
            const _callback = () => {
                this.buttonContinue.disabled = false;
                this.Game.remove()
                const callback = () => this.generateSelectFrame();
                this.UI.progress_SelectionFrame('appear', callback)
            }

            this.UI.progress_TakeImage('disappear', _callback)
        })
    }

    deleteCropImage() {
        // this.cropImage.remove()
        // this.cropImage = null;
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
        return new File([u8arr], filename, {type: mime});
    }

    progress_Game(state, retry) {
        
        if (state == 'init') {
            const _callback = () => {
                this.UI.progress_Game('appear')
            }

            document.querySelector('.button-game-start').disabled = false;
            this.UI.progress_Crop('disappear', _callback)

            gsap.delayedCall(0.2, () => {
                this.Game = new p5(sketch);
      console.log('go')
            })
            this.buttonTakePhoto.disabled = false;
        } else if (state === 'reinit') {
            const _callback = () => {
                this.UI.progress_Game('appear', null, true)
            }
            this.Game.clearGame()
            this.Game.reinit()
            this.UI.progress_TakeImage('disappear', _callback)
            this.buttonTakePhoto.disabled = false;
            
        } else if (state == 'continue') {
            this.Game.playLoop()
        }

    }

    finishGame() {

        this.UI.progress_TakeImage('appear')
        // this.UI.progress_Game('disappear', _callback)
    }
    gameToResult(from) {
        if (!this.resultImage) return;

        console.log('game To Result ', from)


        if (document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb img')) {
            document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb img').remove()
        }
        const image = document.createElement('img');
        image.src = this.resultImage;
        document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb').appendChild(image);

        image.onload = () => {
            gsap.to(image, { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
        }

        // 시작 전
        if (from === 'before_start') {

            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()
            }
            this.UI.progress_Game('disappear', _callback)
        // 게임 진행 중
        } else if (from == 'ing') {
            this.UI.progress_Notification_Complete_Replicate(this.resultImage)
        // 게임 종료
        } else if (from === 'finish') {
            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()
            }
            this.UI.progress_Game('disappear', _callback)
        }
    }

    _finishGame() {
        const _callback = () => {
            this.UI.progress_TakeImage('appear')
            this.Game.stopLoop()
        }
        this.UI.progress_Game('disappear', _callback)
    }

    testError() {
        this.UI.progress_Error('appear', this.CURRENT_REQUEST);
        // console.log(' testError : ',this.Game)
        if (this.UI.progress == 'ing') {
            // this.UI.progress = 'GameStopped'
            // this.Game.stopLoop()
        }
    }

    async initGenerate() {
        this.buttonGenerate.forEach(button => {
            button.addEventListener('click', this.replicate_0.bind(this, button));
        });

        this.buttonFlow.addEventListener('click', () => {
            if (this.CURRENT_REQUEST !== null) {

                let _temp = this.temp(this.CURRENT_REQUEST.split('_')[1]);
                this[this.CURRENT_REQUEST](_temp)
            }
        })
    }
    
    async replicate_0(button) {
        if (!this.cropper) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {


            const { error, progress } = this.UI;

            this.buttonGenerate.forEach(button => button.disabled = true)
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }
            // 자른 이미지 데이터를 파일로 변환
            const croppedDataUrl = this.cropper.cropper
                .getCroppedCanvas({ minWidth: 900, minHeight: 1200, quality: this.imageQuality })
                .toDataURL('image/jpeg', this.imageQuality);
    
            const imageFile = this.dataURLtoFile(croppedDataUrl, 'image.jpg');
            this.init_image = imageFile;
    

            console.log('progress : ', this.UI.progress)

            if (button.dataset.index === 'generate-init') {
                console.log(' GENERATE : generate-init')

                // 게임 실행
                this.progress_Game('init')
                
            }

            if (button.dataset.index === 'error') {

                // 게임 이어서 진행 & 재생성 요청
                // 에러레이어 히든
                console.log(' GENERATE : error')
                this.UI.progress_Error('disappear')
                // this.Game.playLoop()
            }

            if (button.dataset.index === 'regenerate-in-take') {

                gsap.set('.timer', { clearProps: 'all' })
                gsap.set('.floating-continue', { clearProps: 'all' })

                this.progress_Game('reinit')
     
                // 게임 강제 종료 및 재실행
                // take 레이어 히든

                console.log(' GENERATE : regenerate-in-take')
            }
    
            const response = await this.app.replicateImage_First(imageFile);

            this.temp[0] = imageFile;

            this.CURRENT_REQUEST = 'replicate_0';

            

            if (response) {
                console.log('first : ',  response) 
                this.replicate_1(response);
                return;
            }
        } catch (error) {
            console.error('이미지 복제 중 오류 발생:', error);
            this.CURRENT_REQUEST = 'replicate_0';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
        }
    }

    async replicate_1(data) {
        if (!data) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {
            const { error, progress } = this.UI;
            this.temp[1] = data;
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }

    
            const response = await this.app.replicateImage_Second(data);

            this.CURRENT_REQUEST = 'replicate_1';

            if (response) {
                this.replicate_2(response);
                return;
            }

            console.log(response)
    
        } catch (error) {
            console.error('이미지 복제 중 오류 발생:', error);
            this.CURRENT_REQUEST = 'replicate_1';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
        }
    }

    async replicate_2(data) {
        if (!data) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {

            this.temp[2] = data;
            const { error, progress } = this.UI;

            this.buttonGenerate.forEach(button => button.disabled = true)
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }
    
            const response = await this.app.replicateImage_Third(data);

            this.CURRENT_REQUEST = 'replicate_2';

            console.log('THIRD : ', response)

            if (response && response.new_image_url) {
                // console.log('응답 완료 ', this.UI.progress)
                this.resultImage = response.new_image_url;
                this.gameToResult(this.UI.progress)
                this.CURRENT_REQUEST = null;

            } else {
                console.error('새 이미지 URL을 가져오지 못했습니다.');
                this.CURRENT_REQUEST = 'replicate_2';
                this.testError();
                this.buttonGenerate.forEach(button => button.disabled = false)
            }
        } catch (error) {
            console.error('이미지 복제 중 오류 발생:', error);
            this.CURRENT_REQUEST = 'replicate_2';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
            this.buttonGenerate.forEach(button => button.disabled = false)
        }
    }

    async handlePostClick(button) {
        try {

            console.log('POST API')
            this.buttonPost.forEach(button => button.disabled = true)
            this.UI.progress_Loading('appear')
            const newImage = document.createElement('div');
            newImage.classList.add('item-image')
            newImage.classList.add('item-result')
            newImage.classList.add('hidden')
            const _clone = document.querySelector('.swiper-slide-active .thumb').cloneNode(true);
        
            gsap.utils.toArray('img', _clone).forEach(c => {
                c.crossOrigin = 'anonymous';
            })
            newImage.appendChild(_clone);

            document.body.appendChild(newImage)
            
            const dataUrl = await domToJpeg(newImage, { quality: 0.8 }); // 적절한 품질 값 설정

            // // DataURL을 File로 변환
            const imageFile = this.dataURLtoFile(dataUrl, 'new_image.jpg');
            this.generative_image = imageFile;

            // API 호출
            const response = await this.app.generateImage(this.init_image, this.generative_image);

            this.CURRENT_REQUEST = 'generate';


            if (response && response.data.generative_image) {

                this.UI.progress_Loading('disappear')

                this.userOutput = response.data.generative_image;
                console.log(response)

                document.body.setAttribute('data-share-id', response.data.id)
                this.appearResult();
                this.CURRENT_REQUEST = null;

            } else {
                this.CURRENT_REQUEST = 'generate';
                // console.error('새 이미지 URL을 가져오지 못했습니다.');
                this.UI.progress_Loading('disappear')
                this.testError('generate')
            }
        } catch (error) {

            this.CURRENT_REQUEST = 'generate';
            this.testError('generate')
            this.UI.progress_Loading('disappear')
            this.buttonPost.forEach(button => button.disabled = false)
            // console.error('이미지 생성 중 오류 발생:', error);
        } finally {
            this.CURRENT_REQUEST = null
        }
    }

    async initPost() {
        this.buttonPost.forEach(button => {
            button.addEventListener('click', this.handlePostClick.bind(this, button));
        });
    }

    appearResult() {
        const layer = document.querySelector('.layer[data-type="Share"]');
        const image = document.createElement('img');
        image.src = this.userOutput;
        layer.querySelector('.item-image .thumb').appendChild(image);

        image.onload = () => {
            gsap.to(image, { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
        }
        const _callback = () => {
            this.UI.progress_Share('appear')
        }
        this.UI.progress_SelectionFrame('disappear', _callback)
    }
    
    async initSave() {

    }

    async blobToData(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    async inputImage(file) {
        if (!file) return;
        try {
            const resData = await this.blobToData(file);

            document.body.classList.add('has-file')

            let _isToResize = true;

            if (this.cropper) {
                this.cropper.destroy();
                _isToResize = false;
            }

            const newCropper = new CropImage(resData);
            this.cropper = newCropper;

            const _callback = () => {
                this.UI.progress_Crop('appear')
                this.buttonGenerate.forEach(button => button.disabled = false)
                if (_isToResize) {
                    this.cropper.updateSize()
                }
            }
            if (this.app.show_user_guide) {
                this.UI.progress_Onboard('disappear', _callback)
            } else {
                // _callback()
                this.UI.progress_Onboard('disappear', _callback)
            }
        } catch(error) {
            // 에러 발생 - alert
        }
    }


    async initInputGuide() {
        try {
            const response = await this.app.toggleGuide(!document.querySelector('#input-hidden-guide').checked);
            if (response) {
            }
        } catch(error) {
        }
    }

    initInputImage() {

        gsap.utils.toArray('.input-file-container').forEach(input => {
            const fileInput = input.querySelector('input');
            fileInput.addEventListener('change', (event) => {
                if (event.target.files.length > 0) {
                    this.inputImage(event.target.files[0])

                    // this.initInputGuide()
                } else {

                }
            });
        })
    
    }

    generateSelectFrame() {
        const layer = document.querySelector('.layer[data-type="SelectionFrame"]');
        const swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper')

        swiperContainer.innerHTML = `
            <div class="swiper-wrapper">
                ${this.frames.map((frame, i) => `
                    <div class="swiper-slide item-image item-result">
                        <div class="thumb">
                            <div class="thumb-frame">
                                <img src="${frame.card_frame}" alt="frame-${frame.name}" />
                            </div>
                            <div class="thumb-result">
                                <img src="${this.resultImage}" alt="Slide Image" />
                            </div>
                        </div>
                    </div>`
                )
                .join('')}
            </div>
        `;

        const label = layer.querySelector('.info-label');
        const index = layer.querySelector('.info-indicator span');
        layer.querySelector('.swiper-container').appendChild(swiperContainer)
        label.innerHTML = this.frames[0].name;

        let swiper;
        const initSwiper = () => {
            swiper = new Swiper(swiperContainer, {
                // Default parameters
                slidesPerView: 'auto',
                spaceBetween: 18,
                centeredSlides: true,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    init: () => {
                        this.UI.appearSwiper();
                        this.buttonPost.forEach(button => button.disabled = false)
                    },
                },
            });
            swiper.on('slideChange', (e) => {
                const { activeIndex, previousIndex } = e;

                // console.log('swiper change', e)

                label.innerHTML = this.frames[activeIndex].name;
                index.innerHTML = activeIndex + 1;
            })
        };

        const destroySwiper = () => {
            if (swiper) {
                swiper.destroy(true, true);
                swiper = undefined;
                gsap.utils.toArray(".swiper-slide", _swiper).forEach((s) => {
                    gsap.set(s, { clearProps: "all" });
                });
            }
        };
        // const handleResize = () => {
        //     if (window.innerWidth <= 768) {
        //         destroySwiper();
        //     } else {
        //         if (!swiper) {
        //             initSwiper();
        //         }
        //     }
        // };

        // window.addEventListener("resize", handleResize);
        initSwiper();

    }
}