import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { lerp } from './uitls';
import ButtonGlow from './ButtonGlow';
import ScrollAnim from './ScrollAnim';

import TextTranslate from './TextTranslate';

export default class PG_Index {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);

        this.scrollAnim = new ScrollAnim(this)
        this.container = document.querySelector('.item-container')

        this.TEXTANIM_TITLE = new TextTranslate(document.querySelector('.layer-title .title'), 'words')
        this.TEXTANIM_SUB = new TextTranslate(document.querySelector('.layer-title .sub'), 'lines')

        this._count = 0;


        this.isGridView = false;
        this.isInit = false;
        this.elementsState = [];

        // this.isLoad = false;

        this.init()
        // gsap.set('.app', { autoAlpha: 1 })


        // console.log(sessionStorage.getItem('yepp-appearSplash'))

        // this.getDATA()

        if (!sessionStorage.getItem('yepp-appearSplash')) {
        
            gsap.delayedCall(0, () => {
                this.appearSplash()
            })
        } else {
            this.getDATA()
            gsap.set('.layer-intro', { backgroundColor: 'transparent' })
            gsap.delayedCall(0.3, () => {
                this.appearIntro()
            })
        }

        document.querySelector('.button-hidden-splash').addEventListener('click', () => {
            document.querySelector('.button-hidden-splash').disabled = true;
            this.disappearSplash()
        })


        document.querySelector('.button-move-generate').addEventListener('click', () => {
            window.location.href = `/generate`
        })
 
        // this.initSplash()

        // gsap.delayedCall(0, () => {
        //     this.disappearSplash()
        // })
    
    }

    initSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelector('.splash-logo .inner');
        const sub = layer.querySelector('.splash-sub')
        const footer = layer.querySelector('.splash-footer');
        const button = layer.querySelector('.button-glow');
        const nextFloating = document.querySelector('.floating-ui');
        const nextFloatingButton = nextFloating.querySelector('.button-glow');
        const nextFloatingSub = nextFloating.querySelector('.sub');
        const background = document.querySelector('.bridge-background');

        gsap.set(nextFloatingButton, { 
            opacity: 0,
            y: '100%'
        })

        gsap.set(nextFloatingSub, {
            opacity: 0 
        })
    }

    appearSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelector('.splash-logo .inner');
        const logoGradient = logo.querySelector('.logo-gradient');
        const logoSvg = layer.querySelector('.splash-logo .logo-svg');
        const sub = layer.querySelector('.splash-sub')
        const footer = layer.querySelector('.splash-footer');
        const introButtons = document.querySelector('.floating-ui');
        const background = document.querySelector('.bridge-background');
     
        const introButton = new ButtonGlow(layer.querySelector('.button-glow'));


        gsap.to(background, {
            y: '0%',
            duration: 1,
            ease: 'power1.out'
        })

        gsap.to(footer, {
            autoAlpha: 1,
            duration: 0.5,
            ease: 'power1.inOut',
            delay: 1.5
        })
        gsap.to(logoSvg, {
            opacity: 1, 
            duration: 0.5, 
            ease: 'power1.inOut',
            delay: 0.9
        })
        gsap.to(logo, {
            scale: 0.95,
            duration: 1.2, 
            ease: 'power2.out',
            delay: 0.9
        })
        gsap.to(logo, {
            y: '0%',
            duration: 1.2, 
            ease: 'power2.out',
            delay: 0.7
        })
        gsap.to(logoGradient, {
            opacity: 1, 
            duration: 1.8,
            ease: 'power3.inOut',
            delay: 0.5,
            onComplete: () => {
                gsap.delayedCall(0.1, () => {
                    this.getDATA()
                })
                // gsap.to(logoGradient, {
                //     scale: 3,
                //     rotate: '-20deg',
                //     repeat: -1,
                //     yoyo: true,
                //     duration: 4
                // })
            }
        })

        gsap.delayedCall(2.0, () => {
            introButton.anim()
        })

        if (!sessionStorage.getItem('yepp-appearSplash')) {
            sessionStorage.setItem('yepp-appearSplash', "true");
        }

    }


    disappearSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelector('.splash-logo .inner');
        const footer = layer.querySelector('.splash-footer');
        const button = layer.querySelector('.button-glow');
        const nextFloating = document.querySelector('.floating-ui');
        const nextFloatingButton = nextFloating.querySelector('.button-glow');
        const nextFloatingSub = nextFloating.querySelector('.sub');

        const background = document.querySelector('.bridge-background');

        gsap.to(logo, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.0
        })

        gsap.to(footer, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.2
        })

        gsap.to(button, {
            y: '120%',
            duration: 0.7,
            ease: 'power2.out',
            delay: 0.2
        })
        gsap.to(button, {
            opacity: 0,
            duration: 0.6,
            ease: 'power2.out',
            delay: 0.1
        })


        gsap.to([background, layer], { 
            autoAlpha: 0,
            duration: 0.6,
            ease: 'power2.inOut',
            delay: 0.7,
            onComplete: () => {
                gsap.set(layer, { display: 'none' })
                gsap.delayedCall(0.2, () => {
                    this.appearIntro()
                })
            }
        })


        return;
        gsap.to(nextFloating, { 
            opacity: 1,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.4,
        })
        gsap.to(nextFloatingButton, { 
            opacity: 1,
            duration: 0.8,
            ease: 'power1.inOut',
            delay: 0.4,
            onStart: () => {
            }
        })
        

        gsap.to(nextFloatingButton, { 
            y: '0%',
            duration: 0.8,
            ease: 'power2.out',
            delay: 0.4,
            onStart: () => {
            }
        })

        gsap.to(nextFloatingSub, { 
            opacity: 1,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 1.0,
        })
    }

    init() {
        console.log('init : PAGE_Index ', this.app)
        // this.getDATA()
    }


    appearIntro() {
        console.log('appearIntro')
        gsap.set('.layer-intro', { display: 'none' })
        gsap.set('.bridge-background', { display: 'none' })

        this.TEXTANIM_TITLE.appear(0)
        this.TEXTANIM_SUB.appear(0, 0.2)
        document.body.classList.remove('is-lock')
        const nextFloating = document.querySelector('.floating-ui');

        const nextButton = nextFloating.querySelector('.button-glow');

        const _glow = new ButtonGlow(nextButton);
        _glow.complete()

        gsap.to(nextFloating, {
            y: '0%',
            duration: 0.85, 
            ease: 'power2.out',
            delay: 0.5
        })
        
    }


    updateDATA() {
        if (this.next) {
            console.log(this.next)
            this.getDATA(this.next)
        } else {
            this.observer.disable(true)
        }
    }

    async getDATA(url) {
        try {
            const respose = await this.app.getImages(url)

            const { results, next } = respose.data;

 

            if (results) {

                results.map(r => {
                    this.createItem(r)
                })
    
                function replaceNumberWithPlaceholder(url) {
                    return url.replace(/\/accounts\/\d+\//, `/accounts/\${id}/`);
                }
                
                if (results[0].created_by) {
                    // 사용 예시
                    const originalUrl = results[0].created_by.user_profile_url;

                    const updatedUrl = replaceNumberWithPlaceholder(originalUrl);
                    this.app.accountApi = updatedUrl;
                }
 
            }

            if (next) {
                this.next = next.split('com/api')[1];
            } else {
                this.next = null;
            }

            if (!this.isInit) {
                this.scrollAnim.scrollTransition()
            }
            this.scrollObserver()
        } catch(error) {
            console.log(error)
        }
    }
    createItem(data) {

        const { id, generative_image, total_reaction_count, recent_reactions, created_by } = data;

        let created = {
            name: 'username',
            team_name: 'team_name'
        }

        if (data.created_by) {

            const { name, team_name } = data.created_by;

            created.name = name;
            created.team_name = team_name;
            
        }

        const content = document.createElement('div');
        content.classList.add('item-image')
        // content.classList.add('crop')

        content.style.setProperty('--ox', this.opacityValue);

        content.innerHTML = `
            <div class="thumb">
                <img src=${generative_image} alt="${id}">
            </div>
            <div class="information-deck" data-has="${data.has_reaction_user}">
                <div class="tag tag-reaction">
                    <div class="tag-leading tag-reactions"></div>
                    <div class="tag-trailing tag-count"></div>
                </div>
                <div class="tag tag-userinfo">
                    <span class="tag-leading">
                        <span class="tag-text tag-name">${created.name}</span>
                    </span>
                    <div class="tag-trailing">
                        <span class="tag-text tag-team">${created.team_name}</span>
                    </div>
                </div>
            </div>
            <a class="hidden-link" href="/image?id=${id}"></a>
        `;


        //           <span class="symbol "><img src="/assets/reactions/reaction-symbol-${reaction.name}.svg" /></span>`
        if (recent_reactions.length > 0) {
            content.querySelector('.tag-reactions').innerHTML = 
                `${recent_reactions.map((reaction, i) => `
                    <span class="symbol symbol-${reaction.name}"></span>`
                )
                .join('')}`


        
            content.querySelector('.tag-count').innerHTML = `<span class="tag-text">${total_reaction_count}</span>`
        } else {
      
            content.querySelector('.tag-reactions').innerHTML = `<span class="symbol"><img src="/assets/icon/icon-reaction-empty.svg" /></span>`
        }

        


        content.addEventListener('click', this._initClick)

        content.querySelector('img').onload = function(){
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };
        this.scrollAnim.initElementState()
        this.container.appendChild(content);
    }


    scrollObserver() {
        if (this.observer) {
            this.observer.refresh();
            this._count = this._count + 1;
            return;
        }
        this.observer = ScrollTrigger.create({
            // scroller: '.app',
            trigger: '.item-container',
            start: () => `70% bottom`,
            markers: false,
            onEnter: () => {
                console.log('load more', this._count)
                this.updateDATA()
                // gsap.set(document.body, { opacity: 0s.7 })
            }
        })
    }
   
   

    
}

