export default class PG_Login {
    constructor(app) {
        this.app = app;
        this.form = document.querySelector('#form-login');
        this.username = this.form.querySelector('#username');
        this.password = this.form.querySelector('#password');
        this.buttonSubmit = this.form.querySelector('.button-login');
        this.buttonPassword = this.form.querySelector('.button-show-password');

        this.init();
    }

    init() {
        this.initLogin = this.initLogin.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.initEvents();
    }

    initEvents() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                if (this.validateForm()) {
                    this.initLogin();
                }
                event.preventDefault(); 
            }
        });


        this.buttonPassword.addEventListener('click', () => {
            if (this.password.type == 'password') {
                this.password.type = 'text';
                this.form.classList.add('is-text')
            } else {
                this.password.type = 'password';
                this.form.classList.remove('is-text')
            }
        })

        // 버튼 클릭 이벤트 처리
        this.buttonSubmit.addEventListener('click', () => {
            if (this.validateForm()) {
                this.initLogin();
            }
        });
    }

    async initLogin() {
        try {
            const response = await this.app.login(this.username.value, this.password.value);
            if (response) {
            }
        } catch (error) {
        }
    }

    validateForm() {
        const username = this.username.value.trim();
        const password = this.password.value.trim();

        // 유효성 검사
        if (!username) {
            return false;
        }

        if (!password) {
            return false;
        }
        return true;
    }
}
