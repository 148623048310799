import gsap from 'gsap';

export default class ButtonGlow {
    constructor(button) {
        this.button = button;
        this.background = this.button.querySelector('.background');
        this.shadow = this.button.querySelector('.shadow');
        this.label = this.button.querySelector('.label');
        this.container = this.button.querySelector('.glow-effect');

        gsap.set(this.label, { opacity: 0 })
        gsap.set(this.background, { opacity: 0, scaleX: 0.2 })
        
    }

    complete() {
        gsap.set(this.button, {
            autoAlpha: 1,
        })
        gsap.set(this.background, {
            opacity: 1,
        })
        gsap.set(this.background, {
            scaleX: 1,
        })
        gsap.set(this.label, {
            opacity: 1,
        })
        gsap.set(this.container, { 
            autoAlpha: 1, 
        })
        gsap.set(this.shadow, {
            opacity: 1,
        })
    }

    anim() {

        gsap.set(this.button, {
            autoAlpha: 1,
            delay: 0
        })
        gsap.to(this.background, {
            opacity: 1,
            duration: 0.5,
            ease: 'power1.inOut',
            delay: 0
        })
        gsap.to(this.background, {
            scaleX: 1,
            duration: 0.5,
            ease: 'power3.out',
            delay: 0
        })
        gsap.to(this.label, {
            opacity: 1,
            duration: 0.35,
            ease: 'power1.inOut',
            delay: 0.25,
        })
        gsap.to(this.container, { 
            autoAlpha: 1, 
            duration: 0.6,
            ease: 'power1.inOut',
            delay: 0
        })
        gsap.to(this.shadow, {
            opacity: 1,
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.1
        })
        gsap.delayedCall(1.5, () => {
            gsap.utils.toArray('span', this.container).forEach(glow => {
                gsap.to(glow, { rotate: '-360deg', duration: 5, repeat: -1, yoyo: true, repeatDelay: 3, ease: 'power2.inOut' })
            })
        })
        gsap.to(this.container, { scaleX: 0.9, scaleY: 1.0, duration: 1.5, repeat: -1, yoyo: true, repeatDelay: 0.5, ease: 'linear' })
    }
}