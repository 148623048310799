import gsap from 'gsap';
import TextTranslate from './TextTranslate';
import lottie from 'lottie-web';
import animationData from './data/loading.json';

export default class GenerateUI {
    constructor(parent) {
        this.parent = parent;
        
        this.layer = document.querySelector('.layer-game')

        this.progress = 'init';
        window.GenerateUI = this;
        this.init();
    }

    init() {
        this.TEXTANIM_TITLE = new TextTranslate(this.layer.querySelector('.layer-title .title'), 'lines')
        this.TEXTANIM_SUB = new TextTranslate(this.layer.querySelector('.layer-title .sub'))

        gsap.set('.layer-content', { opacity: 0 })
        gsap.set('.layer-ui', { opacity: 0 })


        this.initLoading()
    }

    initLoading() {
        const layer = document.querySelector('.layer-loading');
        this.loadingAnim = lottie.loadAnimation({
            container: layer.querySelector('.loading'), // <div> 요소를 컨테이너로 설정
            renderer: 'svg', // SVG 렌더러를 사용하여 품질을 높임
            loop: true,
            autoplay: false,
            animationData: animationData,
        });
    }

    progress_Loading(state, _callback) {
        const layer = document.querySelector('.layer-loading');
        const loading = layer.querySelector('.loading');

        if (state === 'appear') {

            gsap.set(layer, { zIndex: 9999 })
            if (this.loadingAnim.isPaused) {
                this.loadingAnim.play()
            }

            gsap.to(loading, { opacity: 1, duration: 0.6, ease: 'power1.inOut', delay: 0.4 })

            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, null, null, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }
    }

    _progress_Loading(state, _callback) {
        const layer = document.querySelector('.layer-loading');

        if (state === 'appear') {

            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));
    
            if (this.loadingAnim.isPaused) {
                this.loadingAnim.play()
            }

            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, { _class: TEXTANIM_TITLE, index: 0 }, { _class: TEXTANIM_SUB, index: 0 }, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }
    }
    apparTransition(layer, title, sub, _callback) {
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        console.log({layer, title, sub, _callback})

        layer.classList.add('is-selected');

        gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
        gsap.to(content, { 
            autoAlpha: 1, 
            duration: 0.6,
            ease: 'power1.inOut',
            delay: 0.0,
            onComplete: () => {
           
            }
        })

        gsap.to(floating, { 
            autoAlpha: 1, 
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.6,
            onComplete: () => {
                if (_callback) _callback()
            }
        })

        if (title) {
            title._class.appear(title.index)
        }

        if (sub) {
            sub._class.appear(sub.index, 0.3)
        }
    }
    disapparTransition(layer, _callback) {
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        layer.classList.remove('is-selected');

        gsap.to(layer, { 
            autoAlpha: 0, 
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.0,
            onComplete: () => {
                gsap.set(layer, { display: 'none', pointerEvents: 'none' })
                if (_callback) _callback()
            }
        })
    }

    progress_Onboard(state, _callback) {

        console.log('progress_Onboard : ', state)

        this.progress = 'Onboard';

        const layer = document.querySelector('.layer[data-type="Onboard"]')
        const content = layer.querySelector('.layer-container');
        const floating = layer.querySelector('.layer-ui');
        const video = layer.querySelector('video');

        const toggleVideo = (state) => {
            if (state === 'play') {
                if (video.paused) {
                    video.play()
                    content.classList.add('is-playing')
                }
            } else if (state === 'pause') {
                if (!video.paused) {
                    video.pause()
                    content.classList.remove('is-playing')
                }
            }
        }

        if (state === 'appear') {

            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));
    
    
            const callback = () => {
                toggleVideo('play')
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, { _class: TEXTANIM_TITLE, index: 0 }, { _class: TEXTANIM_SUB, index: 0 }, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })
                toggleVideo('pause')

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }

    }

    progress_Crop(state, _callback) {

        console.log('progress_Crop : ', state)
        const layer = document.querySelector('.layer[data-type="Crop"]');
        const content = layer.querySelector('.layer-container');
        const floating = layer.querySelector('.layer-ui');
        this.progress = 'Crop'

        if (state === 'appear') {

            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));
    
            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, { _class: TEXTANIM_TITLE, index: 0 }, { _class: TEXTANIM_SUB, index: 0 }, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }
    }

    progress_Game(state, _callback, retry) {
        console.log('progress_Game : ',state, _callback, retry)
        this.progress = 'before_start';
        document.querySelector('.button-game-start').removeAttribute('disabled')
        const layer = document.querySelector('.layer[data-type="Game"]')
        const background = layer.querySelector('.background')
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        gsap.set(layer.querySelectorAll('.ui-inner'), { clearProps: 'all' })

        // Show : 온보딩
        if (state === 'appear') {
            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })
            const _callback = () => {
                this.progress_Game_Before_start('appear')
            }

            this.apparTransition(layer, { _class: this.TEXTANIM_TITLE, index: 0 }, { _class: this.TEXTANIM_SUB, index: 0 }, _callback)
            // Hidden : 온보딩
        } else if (state === 'disappear') {
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })
                if (_callback) {_callback()}
            }
            this.disapparTransition(layer, callback)
        }
    }

    progress_Game_Before_start(state, _callback) {
        this.progress = 'before_start'
        const layer = document.querySelector('.layer[data-type="Game"]')
        const content = layer.querySelector('.ui-inner.start');

        // Show : 온보딩
        if (state === 'appear') {
            gsap.set(content, { display: 'flex', pointerEvents: 'auto' })
            gsap.to(content, { autoAlpha: 1, duration: 0.6, ease: 'power1.inOut' })
            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
    
            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.5,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer, content], { clearProps: 'all' })
                    } })
                    if (_callback) _callback()
                }
            })
        }
    }
    progress_Notification_Complete_Replicate(url) {

        this.progress = 'Notification_Complete'
        this.TEXTANIM_TITLE.disappear(0)
        this.TEXTANIM_SUB.disappear(0, 0.4)
        this.TEXTANIM_TITLE.appear(1)
        this.TEXTANIM_SUB.appear(1, 0.4)

        const layer = document.querySelector('.floating-continue');
        gsap.set(layer, { display: 'block' })
        gsap.to(layer, { 
            autoAlpha: 1, 
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.0
        })


    }

    appearSwiper() {
        const swipers = document.querySelectorAll('.layer[data-type="SelectionFrame"] .swiper-slide');

        swipers.forEach(s => {
            const image = s.querySelector('img')

            if (image.complete) {
                gsap.killTweensOf(s, 'opacity')
                gsap.to(s, { opacity: 1, duration: 0.4, ease: 'power1.inOut' });
            } else {
                image.onload = () => {
                    gsap.killTweensOf(s, 'opacity')
                    gsap.to(s, { opacity: 1, duration: 0.4, ease: 'power1.inOut' });
                };
            }
        })

        

    }

    progress_TakeImage(state, _callback) {
        const layer = document.querySelector('.layer[data-type="Take"]');
        const content = layer.querySelector('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        console.log('progress_TakeImage : ', state)
        if (state === 'appear') {
            if (document.body.classList.contains('visible-hint')) {

                document.body.classList.remove('visible-hint')
            }

            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));

            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, { _class: TEXTANIM_TITLE, index: 0 }, { _class: TEXTANIM_SUB, index: 0 }, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }

    }

    progress_SelectionFrame(state, _callback) {
        const layer = document.querySelector('.layer[data-type="SelectionFrame"]');
        const content = layer.querySelector('.layer-content');
        const floating = layer.querySelector('.layer-ui');


        if (state === 'appear') {

            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));
    
            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, null, null, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }

    }

    progress_Share(state, _callback) {
        const layer = document.querySelector('.layer[data-type="Share"]')
        const content = layer.querySelector('.layer-content');
        const floating = layer.querySelector('.layer-ui');
        const video = layer.querySelector('video');

        const toggleVideo = (state) => {
            if (state === 'play') {
                if (video.paused) {
                    video.play()
                    content.classList.add('is-playing')
                }
            } else if (state === 'pause') {
                if (!video.paused) {
                    video.pause()
                    content.classList.remove('is-playing')
                }
            }
        }

        if (state === 'appear') {

            gsap.set(layer.querySelector('.text-content'), { clearProps: 'all' })

            const TEXTANIM_TITLE = new TextTranslate(layer.querySelector('.text-container.title'), 'lines');
            const TEXTANIM_SUB = new TextTranslate(layer.querySelector('.text-container.sub'));
    
            const callback = () => {
                toggleVideo('play')
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, { _class: TEXTANIM_TITLE, index: 0 }, { _class: TEXTANIM_SUB, index: 0 }, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer, content, floating], { clearProps: 'all' })
                toggleVideo('pause')
                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }
    }

    progress_Error(state, errorType, _callback) {
        const layer = document.querySelector('.toast-alert');

        console.log('ERROR : ', errorType)
        if (errorType) {
            if (errorType.includes('replicate_')) {
                layer.querySelector('.text').innerHTML = `과부하 발생! 다른 AI로 교체할게요`;
                layer.querySelector('.button-replicate-flow').classList.add('is-visible') 
                layer.querySelector('.button-post').classList.remove('is-visible') 
            }
            if (errorType == 'generate') {
                layer.querySelector('.text').innerHTML = `오류 발생! 이미지를 다시 붙여볼게요`;
                layer.querySelector('.button-replicate-flow').classList.remove('is-visible') 
                layer.querySelector('.button-post').classList.add('is-visible') 
            }
        }

        // Show : 온보딩
        if (state === 'appear') {
            if (layer.classList.contains('is-selected')) return;
            gsap.set(layer, { display: 'flex', pointerEvents: 'auto' })
            layer.classList.add('is-selected');

            gsap.to(layer, { 
                autoAlpha: 1, 
                duration: 0.25,
                ease: 'power1.inOut',
                onComplete: () => {
                    if (_callback) _callback()
                }
            })

            // Hidden : 온보딩
        } else if (state === 'disappear') {
            gsap.set(layer, { pointerEvents: 'none' })
            gsap.to(layer, { 
                autoAlpha: 0, 
                duration: 0.3,
                ease: 'power1.inOut',
                delay: 0.0,
                onComplete: () => {
                    gsap.set(layer, { display: 'none', onComplete: () => {
                        gsap.set([layer], { clearProps: 'all' })
                        layer.classList.remove('is-selected');
                    } })
                    
                    if (_callback) _callback()
                }
            })
        }
    }
}