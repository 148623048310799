import gsap from 'gsap'

export default class ImageUI {
    constructor(layer) {
        this.layer = layer;
        this.buttons = document.querySelectorAll('.button-reaction')
        this.content = this.layer.querySelector('.thumb img')
        this.tag = this.layer.querySelector('.tag')


        this.state = 'in'
        this.initStyle()

    }

    initStyle() {
        this.buttons.forEach((button, index) => {

            let getter = gsap.getProperty(button);
            button.setAttribute('data-top', getter('top', '%'))
            button.setAttribute('data-left', getter('left', '%'))

        })
    }

    transition() {
        // gsap.killTweensOf(this.buttons, 'all')
        if (this.isAnimating) return;
        this.isAnimating = true;
        gsap.killTweensOf(this.content, 'all')
        gsap.killTweensOf(this.tag, 'all')

        if (this.state == 'in') {
            this.outReactionButtons()
        } else {
            this.inReactionButtons()
        }
    }

    outReactionButtons() {
        this.state = 'out'
        const _left = document.querySelector('.thumb').getBoundingClientRect().left;

        const delayButton = 0.0;
        const buttonOutVerMargin = [100, -100, -100, 100];

        this.buttons.forEach((button, index) => {
            const { top, left, width } = button.getBoundingClientRect();
        
            // const _index = button.getAttribute('data-index');
            const _index = index;

            const isLeft = button.classList.contains('reaction-like') || button.classList.contains('reaction-hot');

            const direction = isLeft ? 1 : -1;

            let targetLeft = (left + width + _left + 10) * direction
            let targetTop = top + buttonOutVerMargin[index];
            gsap.to(button, {
                left: targetLeft,
                top: targetTop,
                duration: 0.5,
                ease: 'power2.inOut',
                delay: delayButton + (_index * 0.02),
            });
        });
        
        gsap.to(this.content, { scale: 1.12, duration: 0.6, ease: 'power2.inOut', delay: 0.1, onComplete: () => {
            this.isAnimating = false;
        } })
        gsap.to(this.tag, { autoAlpha: 0, duration: 0.4, ease: 'power2.inOut'})
    }


    inReactionButtons() {
        this.state = 'in';


        this.buttons.forEach((button, index) => {
            gsap.to(button, { 
                top: `${button.getAttribute('data-top')}%`, 
                left: `${button.getAttribute('data-left')}%`, 
                duration: 0.5, 
                ease: 'circ.out',
                delay: 0.4 + (index * 0.03),
            })

        })

        gsap.to(this.content, { scale: 1.0, duration: 0.6, ease: 'power1.inOut', delay: 0, onComplete: () => {
            this.isAnimating = false;
        } })
        gsap.to(this.tag, { autoAlpha: 1, duration: 0.4, ease: 'power1.inOut', delay: 0.4 })
    }
}