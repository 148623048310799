import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default class ScrollAnim {

    constructor(app) {
        gsap.registerPlugin(ScrollTrigger);
        this.app = app || null;

        this.container = document.querySelector('.item-container')


        this.isGridView = false;

        this.init()
        this.scrollT;
    }

    init() {
        this.initChangeView()
    }

    initElementState() {
        // this.elementsState.push({translateX: this.translateValue, opacity: this.opacityValue, scale: this.scaleValue})
    }

    initChangeView() {
        document.querySelector('.button-grid').addEventListener('click', () => {
            if (!this.container.classList.contains('grid-view')) {
                this.isGridView = true;
                this.container.classList.add('grid-view')
                gsap.to('.item-image', { xPercent: 0, '--ox': 1, duration: 0.2 })
                if (this.app && this.app.observer) {

                    this.app.observer.refresh()
                    console.log(this.app)
                }
            } else {
                this.isGridView = false;
                this.container.classList.remove('grid-view')
                gsap.delayedCall(0.1, () => {
                    // this.updateElements()
                })
            }
        })
    }
    updateElements() {

        var scrolly = this.scrollT;
        
        // console.log(scrolly)
        if(scrolly == undefined) return;

        const windowCenter = window.innerHeight / 2;

        const height = document.querySelector('.item-size').getBoundingClientRect().height;

        const topValue = document.querySelector('.top-height').clientHeight;
        const images = gsap.utils.toArray('.item-image', this.container);
        var length = images.length;
        var con = (scrolly - topValue + windowCenter) / height;
        var now = Math.floor(con);
        var nex1 = now + 1;
        var nex2 = now + 2;
        var pre1 = now - 1;
        var pre2 = now - 2;

        var scaleRatio = 0.2;
        var opacityRatio = 0.8
        var moveRatio = 25 * -1;

        var item = images[now];
        var scaleVal = 1 - Math.abs((con - (now + 0.5))) * scaleRatio;
        var opacityVal = 1 - Math.abs((con - (now + 0.5))) * opacityRatio;
        var moveVal = (con - (now + 0.5)) * moveRatio
        // console.log('333 : ',moveVal)
        if (item) {
            item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
            item.style.opacity = opacityVal;
        }
        // item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;

        if(nex1 < length){
            var item = images[nex1]
            var scaleVal = 1 - Math.abs((con - (nex1 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (nex1 + 0.5))) * opacityRatio;
            var moveVal = (con - (nex1 + 0.5)) * moveRatio;
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }

        if(nex2 < length){
            var item = images[nex2]
            var scaleVal = 1 - Math.abs((con - (nex2 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (nex2 + 0.5))) * opacityRatio;
            var moveVal = (con - (nex2 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        if(pre1 >= 0){
            var item = images[pre1]
            var scaleVal = 1 - Math.abs((con - (pre1 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (pre1 + 0.5))) * opacityRatio;
            var moveVal = (con - (pre1 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        if(pre2 >= 0){
            var item = images[pre2];
            var scaleVal = 1 - Math.abs((con - (pre2 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (pre2 + 0.5))) * opacityRatio;
            var moveVal = (con - (pre2 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        
    }
    scrollTransition() {
        this.scrollT = 0;
        this.updateElements();
        // document.body.classList.remove('is-lock')
        
        gsap.ticker.add(() => {
            this.scrollT += (window.scrollY - this.scrollT) * 0.9;
            this.updateElements()
        })
    }
}